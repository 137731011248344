<template>
  <el-progress :percentage="percent" :show-text="false"></el-progress>
  <template v-for="(item,index) in datalist" :key="item.id">
    <div style="padding:10px">{{index+1}}. <el-tag size="mini" effect="plain">{{item.questions.type}}</el-tag> {{item.questions.question}}</div>
    <el-radio-group v-if="item.questions.type=='单选'" v-model="answerList[index]" >
      <el-radio 
        v-for="optionIt in item.options" 
        :key="optionIt.k" 
        :label="optionIt.k" 
        style="display:block;margin-top:10px;margin-left:10px" 
      >
        {{optionIt.k}}. {{optionIt.v}}
      </el-radio>
    </el-radio-group>
    <el-checkbox-group v-if="item.questions.type=='多选'" v-model="answerList2[index]">
      <el-checkbox 
        v-for="optionIt in item.options" 
        :key="optionIt.k" 
        :label="optionIt.k" 
        style="display:block;margin-top:10px;margin-left:10px" 
      >
        {{optionIt.k}}. {{optionIt.v}}
      </el-checkbox>
    </el-checkbox-group>
    <div style="height:2px;background-color: #eee;margin-top:10px"></div>
  </template>
  <div style="text-align: center;height:60px ;padding: 20px; ">
    <el-button type="primary" @click="submit"  style="width:100%;" v-loading.fullscreen.lock="loading">提交</el-button>
  </div>
</template>

<script>
import { toRaw } from '@vue/reactivity'
export default {
  data() {
    return {
      loading:false,
      id:0,
      datalist: [],
      answerList:[],
      answerList2:[],
      percent:0,
      seconds:0,
      maxMins:0
    };
  },
  mounted: function () {
    this.id = this.$route.params.id
    this.$net.exam(this.id).then(({ data }) => {
      const seconds = data.exam.seconds
      const maxMins = data.examMaxMins
      const time = seconds > 0 ? (maxMins-seconds/60) : 0
      this.seconds = seconds
      this.maxMins = maxMins
      this.percent = seconds/60*100/maxMins
      this.datalist = data.exam.examAnswerList.map(it=>{
          it.options = JSON.parse(it.questions.items.replace(/\t/g,''))
          return it
      })
      this.answerList2 = Array(this.datalist.length).fill([])
      this.$alert('本次考试'+maxMins+'分钟'+(time?('(剩余'+time.toFixed(1)+'分钟)'):'')+'，请在时间限制内完成考试','提示');
      this.updateTime()
    });
  },
  unmounted: function () {
    clearInterval(this.interval);
  },
  methods:{
    submit (valid) {
      const skipValid = valid == 'skip'
      const datalist = toRaw(this.datalist)
      const answerList = toRaw(this.answerList)
      const answerList2 = toRaw(this.answerList2)
      const list = []
      for(let i=0 ; i < datalist.length ; i++){
        const it = datalist[i]
        let answer = answerList[i] 
        if(it.questions.type=='多选'){
          answer = answerList2[i].sort().join(',')
        }
        if(!skipValid&&!answer){
          this.$alert('请把答案填写完整','错误');
          return 
        }
        list.push({
          questionsId: it.questions.id,
          answer: answer
        })
      }
      const data = {
          id:this.id,
          answerList:list
      }
      this.loading = true
      this.$net.examSubmit(data).then(({data})=>{
        this.loading = false
        let score = data.exam.score
        this.$alert('你这次考试得了'+score+'分','考试成绩');
        this.$router.back()
      })
      
    },
    
    updateTime() {
      const page = this
      this.interval = setInterval(function(){
        console.log('updateTime')
        let seconds = page.seconds+5
        if(seconds/60>page.maxMins){
          clearInterval(page.interval);
          page.submit('skip')
          return
        }
        page.seconds = seconds,
        page.percent = seconds/60*100/page.maxMins
        page.$net.examUpdateTime({
            id:page.id,
            seconds: seconds
        })
      },5000)
    },
  }
};
</script>